import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import {
  List,
  DatagridConfigurable,
  TextField,
  SimpleList,
  downloadCSV,
  TextInput,
  DateTimeInput
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { DefaultPagination } from '../../pagination/Pagination';
import QRCodeButton from '../../button/QRCodeButton';
import moment from 'moment';
import { DefaultListActions, FieldWrapper } from '../../actions/ListActions';
import DateField from '../../field/DateField';

const exporter = (sites) => {
  const sitesForExport = sites.map((site) => {
    let siteForExport = {};
    siteForExport.site_code = site.site.siteCode;
    siteForExport.state = site.site.address.state;
    siteForExport.country = site.site.address.country;
    siteForExport.site_name = site.site.siteName;
    siteForExport.company_name = site.company.companyName;
    siteForExport.clean_up_date =
      site.userCompanySiteJobs != null && site.userCompanySiteJobs.endTimestamp
        ? moment(site.userCompanySiteJobs.endTimestamp).format('YYYY-MM-DD HH:mm:ss')
        : '';
    siteForExport.job_count = site.jobCount;
    siteForExport.role = site.companySiteRole ? site.companySiteRole.role : '';
    return siteForExport;
  });
  jsonExport(
    sitesForExport,
    {
      headers: [
        'site_code',
        'state',
        'country',
        'site_name',
        'company_name',
        'clean_up_date',
        'job_count',
        'role',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'company_shared_site_list');
    }
  );
};

const filter = [
  <TextInput source="site->siteCode_like" label="locspect.resource.sharedSites.fields.code" />,
  <TextInput
    source="site->address->state_like"
    label="locspect.resource.sharedSites.fields.state"
  />,
  <TextInput
    source="site->address->country_like"
    label="locspect.resource.sharedSites.fields.country"
  />,
  <TextInput source="site->siteName_like" label="locspect.resource.sharedSites.fields.siteName" />,
  <TextInput
    source="company->companyName_like"
    label="locspect.resource.sharedSites.fields.companyName"
  />,
  <TextInput
    source="companySiteRole->role_like"
    label="locspect.resource.sharedSites.fields.role"
  />,
  <DateTimeInput
    source="userCompanySiteJobs->endTimestamp_ge"
    label="locspect.resource.sharedSites.fields.cleanUpDateStart"
  />,
  <DateTimeInput
    source="userCompanySiteJobs->endTimestamp_le"
    label="locspect.resource.sharedSites.fields.cleanUpDateEnd"
  />,
];

export const CompanySharedSiteList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <List actions={<DefaultListActions />} perPage={25} pagination={<DefaultPagination />} exporter={exporter} filters={filter}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => `${record.site.siteCode} | ${record.company.companyName}`}
          secondaryText={(record) => `${record.site.siteName}`}
          tertiaryText={(record) =>
            `${
              record.userCompanySiteJobs.endTimestamp
                ? `${moment(record.userCompanySiteJobs.endTimestamp).format('YYYY-MM-DD')}`
                : ''
            }`
          }
        />
      ) : (
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField label="locspect.resource.sharedSites.fields.code" source="site.siteCode" />
          <TextField
            label="locspect.resource.sharedSites.fields.state"
            source="site.address.state"
          />
          <TextField
            label="locspect.resource.sharedSites.fields.country"
            source="site.address.country"
          />
          <TextField label="locspect.resource.sharedSites.fields.siteName" source="site.siteName" />
          <TextField
            label="locspect.resource.sharedSites.fields.companyName"
            source="company.companyName"
          />
          <TextField
            label="locspect.resource.sharedSites.fields.role"
            source="companySiteRole.role"
          />
          <DateField
            label="locspect.resource.sharedSites.fields.cleanUpDate"
            source="userCompanySiteJobs.endTimestamp"
            sortBy="userCompanySiteJobs.endTimestamp"
          />
          <TextField label="locspect.resource.managedSites.fields.jobCount" source="jobCount" sortable={false} />
          <FieldWrapper label="QR">
            <QRCodeButton />
          </FieldWrapper>
        </DatagridConfigurable>
      )}
    </List>
  );
};
